var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--block-header" },
    [
      _vm.topStrip ? _c("div", { staticClass: "top-strip" }) : _vm._e(),
      _c(
        "v-container",
        {
          staticClass: "contents",
          style: { backgroundImage: "url(" + _vm.bannerImg + ")" },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                !_vm.noBackBtn
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "textLight--text mew-heading-3 font-weight-medium cursor-pointer pa-1",
                        on: { click: _vm.closeBanner },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "textLight" } },
                          [_vm._v("mdi-arrow-left-thick")]
                        ),
                        _vm._v(" Dapp center "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "py-6 d-flex align-center justify-center",
                  staticStyle: { "min-height": "150px" },
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-block d-sm-flex align-center justify-center text-center",
                        },
                        [
                          _vm.titleIcon
                            ? _c("img", {
                                staticClass: "mr-sm-3",
                                attrs: {
                                  src: _vm.titleIcon,
                                  height: "40",
                                  alt: "Icon",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "white--text mew-subtitle font-weight-bold text-center",
                            },
                            [_vm._v(" " + _vm._s(_vm.textObj.title) + " ")]
                          ),
                        ]
                      ),
                      _vm.textObj.subtext
                        ? _c(
                            "div",
                            {
                              staticClass: "text-center mt-1 mx-auto",
                              class: _vm.textObj.subtextClass
                                ? _vm.textObj.subtextClass
                                : "white--text",
                              staticStyle: { "max-width": "450px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.textObj.subtext) + " ")]
                          )
                        : _vm._e(),
                      _vm._t("body"),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_vm._t("right")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }