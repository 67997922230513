var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-5 pt-sm-9 px-sm-13", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { clas: "align-center justify-center", "no-gutters": "" } },
        [
          _c(
            "div",
            { staticClass: "dapp-icon" },
            [
              _vm.dappImg !== ""
                ? _c("v-img", {
                    attrs: {
                      src: _vm.dappImg,
                      width: "88",
                      height: "88",
                      contain: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", { staticClass: "pl-5 pl-sm-8" }, [
            _c(
              "div",
              { staticClass: "router-link" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "blue500--text",
                    attrs: { to: { name: _vm.ROUTE_DAPP_CENTER.NAME } },
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "align-center",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "blue500--text",
                            attrs: { size: "20" },
                          },
                          [_vm._v(" mdi-chevron-left")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.$t("common.dapps")))]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "mew-heading-1 py-2" }, [
              _vm._v(_vm._s(_vm.dappName)),
            ]),
            _c("p", { staticClass: "textMedium--text mb-0" }, [
              _vm._v(" " + _vm._s(_vm.dappText) + " "),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }